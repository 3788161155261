
<template>
  <div class="body add-vehicle">
    <p class="form-title">基本信息</p>
    <el-form
      :model="addForm"
      :rules="rules"
      :inline="true"
      ref="addForm"
      label-width="110px"
      label-position="left"
      class="demo-form-inline dialog-form dialog-form-inline"
      style="padding-bottom: 0"
    >
      <el-form-item label="车牌号：" prop="cph">
        <el-input
          v-model.trim="addForm.cph"
          placeholder="请输入车牌号"
          :disabled="monitoringDisabled"
        ></el-input>
      </el-form-item>

      <el-form-item label="所属企业：" prop="companyId">
        <companySelect
          ref="companySelect"
          :clearable="true"
          :searchable="true"
          :disabled="monitoringDisabled"
          :companyTree="companyList"
          :valueName="valueNameAdd"
          :value="valueAdd"
          @getValue="getAddGroupId"
        ></companySelect>
      </el-form-item>
      <!--车架号：编辑时不校验  -->
      <el-form-item label="车架号：" :prop="isvehicleNoEdit ? 'vehicleNo' : ''">
        <el-input
          v-model.trim="addForm.vehicleNo"
          placeholder="请输入车架号"
          :disabled="!isvehicleNoEdit"
        ></el-input>
      </el-form-item>

      <el-form-item label="车辆状态：" prop="vehicleStatus">
        <el-select disabled clearable v-model.trim="addForm.vehicleStatus">
          <el-option
            v-for="item in vehicleStatusList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
        <!-- {{vehicleStatusList.find(item=>item.dictCode == addForm.vehicleStatus).dictValue || ''}} -->
      </el-form-item>

      <el-form-item label="车牌颜色：" prop="licensePlateColor">
        <el-select clearable v-model.trim="addForm.licensePlateColor" :disabled="monitoringDisabled">
          <el-option
            v-for="item in licensePlateColorList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车辆类型：" prop="type">
        <el-select clearable v-model.trim="addForm.type" :disabled="monitoringDisabled">
          <el-option
            v-for="item in typeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="车身颜色：" prop="vehicleColor">
        <el-select clearable v-model.trim="addForm.vehicleColor">
          <el-option
            v-for="item in vehicleColorList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="动力类型：">
        <el-select clearable v-model.trim="addForm.powerType">
          <el-option
            v-for="item in powerTypeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="城市：">
        <el-cascader
          size="large"
          :options="cityList"
          v-model="addForm.city"
        ></el-cascader>
      </el-form-item>

      <el-form-item label="车型：" prop="model">
        <el-select clearable v-model.trim="addForm.model">
          <el-option
            v-for="item in modelList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="发动机号：" prop="engineNo">
        <el-input
          v-model.trim="addForm.engineNo"
          placeholder="请输入发动机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="座位数：">
        <el-row>
          <el-col :span="23"
            ><el-input-number
              v-model="addForm.seatNum"
              controls-position="right"
              :min="1"
              :max="199"
              class="add-vehicle-number"
            >
            </el-input-number
          ></el-col>
          <el-col :span="1"> <span>座</span></el-col>
        </el-row>
      </el-form-item>

      <el-form-item label="安装时间：" prop="installDate">
        <el-date-picker
          v-model="addForm.installDate"
          placeholder="选择日期"
          type="date"
          :disabled="monitoringDisabled"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="车辆类别：" prop="vehicleClass">
        <el-select clearable v-model.trim="addForm.vehicleClass">
          <el-option
            v-for="item in vehicleClassList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="环卫车辆类别：" prop="vehicleClassAssanation">
        <el-select clearable v-model.trim="addForm.vehicleClassAssanation">
          <el-option
            v-for="item in vehicleClassAssanationList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="备注：" prop="remark">
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 2 }"
          maxlength="500"
          show-word-limit
          placeholder="请输入内容"
          v-model.trim="addForm.remark"
        ></el-input>
      </el-form-item>
    </el-form>
    <p class="form-title form-title2">
      <span>车辆终端信息</span>
      <el-button
        type="primary"
        size="small"
        @click="onAddTerminal"
        v-if="!disabled && !monitoringDisabled"
        >添加设备</el-button
      >
    </p>
    <el-form
      ref="form"
      :model="form"
      class="dialog-form dialog-form-table"
      :disabled="disabled || monitoringDisabled"
    >
      <el-table :data="form.terminalData" class="deviceTable">
        <el-table-column
          type="index"
          label="序号"
          width="100"
        ></el-table-column>
        <el-table-column label="设备号" width="330">
          <template slot-scope="scope">
            <el-form-item
              :prop="`terminalData.${scope.$index}.deviceNo`"
              :rules="tableRules.deviceNo"
            >
              <el-select
                v-model="scope.row.deviceNo"
                filterable
                remote
                @change="onChangeDeviceNo(scope.$index, scope.row)"
                :remote-method="remoteMethod"
                placeholder="请输入关键字"
                cellclassname="carGroupSelect"
              >
                <el-option
                  v-for="(item, key) in deviceNoList"
                  :label="`${item.deviceNo}(${
                    item.serialNo ? item.serialNo : '暂无自编号'
                  })`"
                  :value="`${item.deviceNo}(${
                    item.serialNo ? item.serialNo : '暂无自编号'
                  })`"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="deviceTypeName"
          label="设备类型"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column
          prop="SIMNo"
          label="SIM卡号"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column label="摄像头数量" width="220">
          <template slot-scope="scope">
            <el-form-item
              :prop="`terminalData.${scope.$index}.videoChanel`"
              :rules="tableRules.videoChanel"
            >
              <el-select v-model="scope.row.videoChanel">
                <el-option label="0" value="0"></el-option>
                <el-option label="1" value="1"></el-option>
                <el-option label="2" value="2"></el-option>
                <el-option label="3" value="3"></el-option>
                <el-option label="4" value="4"></el-option>
                <el-option label="5" value="5"></el-option>
                <el-option label="6" value="6"></el-option>
                <el-option label="7" value="7"></el-option>
                <el-option label="8" value="8"></el-option>
                <el-option label="9" value="9"></el-option>
                <el-option label="10" value="10"></el-option>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" v-if="!disabled && !monitoringDisabled">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              sort="danger"
              :disabled="disabled || monitoringDisabled"
              @click="onDeleteTerminal(scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div>
      <!-- 821新增 -->
      <!-- <div v-if="rowData"> -->
      <p class="form-title form-title2">
        <span>行驶证信息</span>
      </p>
      <div class="head">
        <p>
          <span>行驶证号码：</span>
          <el-input
            v-model.trim="addForm.vehicleDrivingLicense.drivingNumber"
            placeholder="请输入行驶证号码"
          ></el-input>
        </p>
        <!-- <p>
          <span>生效时间：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.drivingStart"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            type="date"
          ></el-date-picker>
        </p> -->
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.drivingEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.drivingEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
      </div>
      <div class="head">
        <p>
          <span>道路运输证号：</span>
          <el-input
            v-model.trim="addForm.vehicleDrivingLicense.transportNumber"
            placeholder="请输入道路运输证号"
          ></el-input>
        </p>
        <!-- <p>
          <span>生效时间：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.transportStart"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            type="date"
          ></el-date-picker>
        </p> -->
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.transportEnd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.transportEnd
                ? 'expired'
                : ''
            "
            type="date"
          ></el-date-picker>
        </p>
      </div>
      <div class="head">
        <p>
          <span>线路牌号：</span>
          <el-input
            v-model.trim="addForm.vehicleDrivingLicense.licenseNumber"
            placeholder="请输入线路牌号"
          ></el-input>
        </p>
        <!-- <p>
          <span>生效时间：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.licenseStart"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            type="date"
          ></el-date-picker>
        </p> -->
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.licenseEnd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.licenseEnd
                ? 'expired'
                : ''
            "
            type="date"
          ></el-date-picker>
        </p>
      </div>
      <div class="head">
        <p style="line-height: 32px">客运班线经营许可证有效期：</p>
        <p>
          <el-date-picker
            v-model="addForm.vehicleDrivingLicense.lineAllowEnd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.lineAllowEnd
                ? 'expired'
                : ''
            "
            type="date"
          ></el-date-picker>
        </p>
      </div>
      <!-- 行驶证照片 道路运输证照片 -->
      <div class="head">
        <p>
          <span style="line-height: 148px; text-align: center">行驶证照片</span>
          <el-upload
            :action="uploadUrl()"
            :headers="getHeader()"
            accept="image/png, image/gif, image/jpg, image/jpeg"
            list-type="picture-card"
            :limit="1"
            :file-list="drivingPhotos"
            :before-remove="handleBeforeRemoveDrive"
            :on-success="handleAvatarSuccessDrive"
            :class="{ disUoloadSty: isPhotoExceed }"
            ref="upload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </p>
        <p>
          <span style="line-height: 148px; text-align: center"
            >道路运输证照片</span
          >
          <el-upload
            :action="uploadUrl()"
            :headers="getHeader()"
            accept="image/png, image/gif, image/jpg, image/jpeg"
            list-type="picture-card"
            :limit="1"
            :file-list="transportPhotos"
            :before-remove="handleBeforeRemove"
            :on-success="handleAvatarSuccess"
            :class="{ disUoloadSty: isPhotoExceed2 }"
            ref="upload"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </p>
      </div>
      <p class="form-title form-title2">
        <span>保险信息</span>
      </p>
      <div class="head">
        <p>
          <span>交强险生效：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.trafficStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.trafficEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.trafficEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
        <p>
          <span>承保公司：</span>
          <el-input
            v-model.trim="addForm.vehicleInsurance.trafficInsurance"
            placeholder="请输入承保公司"
          ></el-input>
        </p>
      </div>
      <div class="head">
        <p>
          <span>商业险生效：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.tradeStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.tradeEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.tradeEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
        <p>
          <span>承保公司：</span>
          <el-input
            v-model.trim="addForm.vehicleInsurance.tradeInsurance"
            placeholder="请输入承保公司"
          ></el-input>
        </p>
      </div>
      <div class="head">
        <p>
          <span>承运险生效：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.underwriteStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.underwriteEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.underwriteEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
        <p>
          <span>承保公司：</span>
          <el-input
            v-model.trim="addForm.vehicleInsurance.underwriteInsurance"
            placeholder="请输入承保公司"
          ></el-input>
        </p>
      </div>
      <div class="head">
        <p>
          <span>年审日期：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.yearExamineStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.yearExamineEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.yearExamineEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
      </div>
      <div class="head">
        <p>
          <span>一维日期：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.oneDefendStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.oneDefendEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.oneDefendEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
      </div>
      <div class="head">
        <p>
          <span>二维日期：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.twoDefendStart"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          ></el-date-picker>
        </p>
        <p>
          <span>有效期至：</span>
          <el-date-picker
            v-model="addForm.vehicleInsurance.twoDefendEnd"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
            :class="
              vehicleCertificateExpireData &&
              vehicleCertificateExpireData.twoDefendEnd
                ? 'expired'
                : ''
            "
          ></el-date-picker>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  insertVehicle,
  updateVehicle,
  getBusinessCompanyTree,
  queryByDeviceNo,
  queryAreaList,
  queryByVehicleNo,
  getVehicleInfo,
  queryVehicleDetailInfo,
  queryDictsByCodes,
  queryVehicleCertificateExpireDetail
} from '@/api/lib/api.js'
import config from "@/api/static/config";
import companySelect from '@/components/treeSelect/companySelect.vue'
import { checkPlateNumber, checkFrameNumver, formatDate, formatDay } from '@/common/utils/index.js'

export default {
  components: {
    companySelect
  },
  props: {
    // 处理类型
    dealType: {
      type: Number
    },
    //编辑时暂存的车架号
    vehicleNo: {
      type: String
    },
    rowData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    //821车辆管理终端信息列表不可编辑
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * 2023.10.12 田产品：综合监控页面修改车辆必填信息不可修改，只能在资源管理系统里面修改，直接禁掉，所用字段名：monitoringDisabled,此字段默认为false
     */
    monitoringDisabled:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isPhotoExceed: false,//上传照片按钮隐藏
      isPhotoExceed2: false,//上传照片按钮隐藏
      isvehicleNoEdit: true,//编辑状态不可修改
      value: null,
      valueName: null,
      valueNameAdd: null,
      valueAdd: null,
      detailForm: {},
      addForm: {
        cph: '',
        vehicleNo: '',
        companyId: '',
        powerType: '',
        licensePlateColor: '',
        type: '',
        color: '',
        vehicleStatus: '1',
        engineNo: '',
        model: '',
        city: [],
        deviceNos: [],
        seatNum: null,
        installDate: new Date(),
        remark: null,
        //环卫新增车辆类别
        vehicleClassAssanation: "",
        //821新增
        vehicleClass: '',
        vehicleDrivingLicense: {
          drivingEnd: '',//行驶证有效截至日期
          drivingStart: '',
          drivingImg: '',//行驶证图片path
          drivingNumber: '',//行驶证号码
          licenseEnd: '',//线路牌号有效截至日期
          licenseStart: '',
          licenseNumber: '',
          transportNumber: '',//道路运输证号
          transportEnd: '',
          transportStart: '',
          transportImg: '',
          lineAllowEnd: '',//客运班线经营许可证有效期
        },//车辆行驶证数据体
        vehicleInsurance: {
          trafficStart: '',//交强险
          trafficEnd: '',
          trafficInsurance: '',
          tradeStart: '',//商业险
          tradeEnd: '',
          tradeInsurance: '',
          underwriteStart: '',//承运险
          underwriteEnd: '',
          underwriteInsurance: '',
          yearExamineStart: '',//年审
          yearExamineEnd: '',
          oneDefendStart: '',//一维
          oneDefendEnd: '',
          twoDefendStart: '',//二维
          twoDefendEnd: '',
        },//
      },
      vehicleCertificateExpireData: {},
      drivingPhotos: [],
      transportPhotos: [],
      vehicleClassAssanationList: [],//环卫车辆类别
      vehicleClassList: [
        {
          dictCode: '18',
          dictValue: '巡回车'
        },
        {
          dictCode: '19',
          dictValue: '公务车'
        },
        {
          dictCode: '20',
          dictValue: '通勤车'
        },
      ],
      rules: {
        cph: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { validator: checkPlateNumber, trigger: 'blur' }
        ],
        vehicleNo: [
          { required: true, message: '请输入车架号', trigger: 'blur' },
          { validator: checkFrameNumver, trigger: 'blur' }
        ],
        companyId: [
          { required: true, message: '请选择所属企业', trigger: 'change' }
        ],
        powerType: [
          { required: true, message: '请选择动力类型', trigger: 'change' }
        ],
        licensePlateColor: [
          { required: true, message: '请选择车牌颜色', trigger: 'change' }
        ],
        type: [
          { required: true, message: '请选择车辆类型', trigger: 'change' }
        ],
        color: [
          { required: true, message: '请选择车身颜色', trigger: 'change' }
        ],
        vehicleStatus: [
          { required: true, message: '请选择车辆状态', trigger: 'change' }
        ],
        installDate:[
          { required: true, message: '请选择安装时间', trigger: 'change' }
        ],
        city: [{ required: true, message: '请选择城市', trigger: 'change' }]
      },
      terminalData: [
        {
          deviceNo: '',
          deviceType: '',
          deviceTypeName: '',
          deviceModelId: '',
          SIMNo: '',
          videoChanel: ''
        }
      ],
      companyList: [],
      vehicleStatusList: [],
      powerTypeList: [],
      licensePlateColorList: [],
      typeList: [],
      vehicleColorList: [],
      modelList: [],
      deviceNoList: [],
      cityList: [],
      form: {
        terminalData: [
          {
            deviceNo: '',
            deviceType: '',
            deviceTypeName: '',
            deviceModelId: '',
            SIMNo: '',
            videoChanel: ''
          }
        ]
      },
      tableRules: {
        deviceNo: [{ required: true, message: '请选择', trigger: 'change' }],
        videoChanel: [{ required: true, message: '请选择', trigger: 'change' }]
      }
    }
  },
  watch: {
    "drivingPhotos" (val) {
      if (val.length === 1) {
        this.isPhotoExceed = true;
      } else {
        this.isPhotoExceed = false;
      }
    },
    "transportPhotos" (val) {
      if (val.length === 1) {
        this.isPhotoExceed2 = true;
      } else {
        this.isPhotoExceed2 = false;
      }
    },
  },
  mounted () {
    /** 编辑 */
    if (this.vehicleNo) {
      this.isvehicleNoEdit = false;
      queryByVehicleNo({ vehicleNo: this.vehicleNo }).then((res) => {
        if (res.code === 1000) {
          if (res.data.length > 0) {
            this.form.terminalData = []
            res.data.forEach((element) => {
              this.form.terminalData.push({
                SIMNo: element.sim,
                deviceType: element.deviceModelType,
                deviceTypeName: element.deviceName,
                deviceModelId: element.id,
                deviceNo: `${element.deviceNo}(${element.serialNo ? element.serialNo : '暂无自编号'
                  })`,
                videoChanel: element.videoChanel
              })
            })
          } else {
            this.deviceNoList = []
            this.form.terminalData = [
              {
                deviceNo: '',
                deviceType: '',
                deviceTypeName: '',
                deviceModelId: '',
                SIMNo: '',
                videoChanel: ''
              }
            ]
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    } else {
      this.valueAdd = null
      this.valueNameAdd = null
      this.form.terminalData = [
        {
          deviceNo: '',
          deviceType: '',
          deviceTypeName: '',
          deviceModelId: '',
          SIMNo: '',
          videoChanel: ''
        }
      ]
      this.deviceNoList = []
      this.isvehicleNoEdit = true
    }
  },
  methods: {
    //行驶证照片
    // 移除照片确认
    handleBeforeRemoveDrive (file, fileList) {
      this.drivingPhotos.forEach((item, index) => {
        if (file.response) {
          if (item === file.response.data) {
            this.drivingPhotos.splice(index, 1);
          }
        } else {
          if (item.url === file.url) {
            this.drivingPhotos.splice(index, 1);
          }
        }
      });
      this.addForm.vehicleDrivingLicense.drivingImg = '';
    },
    //道路运输证-移除照片
    handleBeforeRemove (file, fileList) {
      this.transportPhotos.forEach((item, index) => {
        if (file.response) {
          if (item === file.response.data) {
            this.transportPhotos.splice(index, 1);
          }
        } else {
          if (item.url === file.url) {
            this.transportPhotos.splice(index, 1);
          }
        }
      });
      this.addForm.vehicleDrivingLicense.transportImg = '';
    },
    //行驶证照片
    handleAvatarSuccessDrive (res, file) {
      if (res.code === 1000) {
        this.drivingPhotos = [res.data];
      }
    },
    // 成功添加照片-道路运输证
    handleAvatarSuccess (res, file) {
      if (res.code === 1000) {
        this.transportPhotos = [res.data];
      }
    },

    // 上传接口
    uploadUrl () {
      return config.apiBaseUrl + "base/driver/uploadDriverPicture";
    },
    // 上传请求头
    getHeader () {
      return {
        Authorization:
          sessionStorage.getItem("token") || localStorage.getItem("token"),
        token: sessionStorage.getItem("token") || localStorage.getItem("token")
      };
    },
    onSave () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$emit('onLoading', true);
              let data = { ...this.addForm }
              let deviceNos = []
              data.city = JSON.stringify(data.city);
              data.installDate && (data.installDate = formatDate(data.installDate));
              this.form.terminalData.forEach((item) => {
                if (item.deviceNo) {
                  let deviceNo = item.deviceNo.split('(')[0]
                  deviceNos.push({
                    deviceNo: deviceNo,
                    deviceModelId: item.deviceModelId,
                    deviceModelType: item.deviceType,
                    videoChanel: item.videoChanel
                  })
                }
              })
              data.deviceNos = deviceNos;
              /**
               * 821新增数据
               */
              //图片类

              this.drivingPhotos.forEach(item => {
                if (item.name) {
                  data.vehicleDrivingLicense.drivingImg = item.url;
                } else {
                  data.vehicleDrivingLicense.drivingImg = item
                }
              })
              this.transportPhotos.forEach(item => {
                if (item.name) {
                  data.vehicleDrivingLicense.transportImg = item.url;
                } else {
                  data.vehicleDrivingLicense.transportImg = item
                }
              })
              //时间类
              data.vehicleDrivingLicense.drivingEnd && (data.vehicleDrivingLicense.drivingEnd = formatDay(data.vehicleDrivingLicense.drivingEnd));
              data.vehicleDrivingLicense.drivingStart && (data.vehicleDrivingLicense.drivingStart = formatDay(data.vehicleDrivingLicense.drivingStart));
              data.vehicleDrivingLicense.licenseEnd && (data.vehicleDrivingLicense.licenseEnd = formatDay(data.vehicleDrivingLicense.licenseEnd));
              data.vehicleDrivingLicense.licenseStart && (data.vehicleDrivingLicense.licenseStart = formatDay(data.vehicleDrivingLicense.licenseStart));
              data.vehicleDrivingLicense.transportEnd && (data.vehicleDrivingLicense.transportEnd = formatDay(data.vehicleDrivingLicense.transportEnd));
              data.vehicleDrivingLicense.transportStart && (data.vehicleDrivingLicense.transportStart = formatDay(data.vehicleDrivingLicense.transportStart));
              data.vehicleDrivingLicense.lineAllowEnd && (data.vehicleDrivingLicense.lineAllowEnd = formatDay(data.vehicleDrivingLicense.lineAllowEnd));

              data.vehicleDrivingLicense.trafficStart && (data.vehicleDrivingLicense.trafficStart = formatDay(data.vehicleDrivingLicense.trafficStart));
              data.vehicleDrivingLicense.trafficEnd && (data.vehicleDrivingLicense.trafficEnd = formatDay(data.vehicleDrivingLicense.trafficEnd));
              data.vehicleDrivingLicense.tradeStart && (data.vehicleDrivingLicense.tradeStart = formatDay(data.vehicleDrivingLicense.tradeStart));
              data.vehicleDrivingLicense.tradeEnd && (data.vehicleDrivingLicense.tradeEnd = formatDay(data.vehicleDrivingLicense.tradeEnd));
              data.vehicleDrivingLicense.underwriteStart && (data.vehicleDrivingLicense.underwriteStart = formatDay(data.vehicleDrivingLicense.underwriteStart));
              data.vehicleDrivingLicense.underwriteEnd && (data.vehicleDrivingLicense.underwriteEnd = formatDay(data.vehicleDrivingLicense.underwriteEnd));
              data.vehicleDrivingLicense.yearExamineStart && (data.vehicleDrivingLicense.yearExamineStart = formatDay(data.vehicleDrivingLicense.yearExamineStart));
              data.vehicleDrivingLicense.yearExamineEnd && (data.vehicleDrivingLicense.yearExamineEnd = formatDay(data.vehicleDrivingLicense.yearExamineEnd));
              data.vehicleDrivingLicense.oneDefendStart && (data.vehicleDrivingLicense.oneDefendStart = formatDay(data.vehicleDrivingLicense.oneDefendStart));
              data.vehicleDrivingLicense.oneDefendEnd && (data.vehicleDrivingLicense.oneDefendEnd = formatDay(data.vehicleDrivingLicense.oneDefendEnd));
              data.vehicleDrivingLicense.twoDefendStart && (data.vehicleDrivingLicense.twoDefendStart = formatDay(data.vehicleDrivingLicense.twoDefendStart));
              data.vehicleDrivingLicense.twoDefendEnd && (data.vehicleDrivingLicense.twoDefendEnd = formatDay(data.vehicleDrivingLicense.twoDefendEnd));
              if (this.vehicleNo) {
                updateVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('修改成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              } else {
                insertVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('新增成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              }
            }

          })
        }
      })
    },
    // 新增/修改车辆
    onSaveOld () {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.$emit('onLoading', true)
              let data = { ...this.addForm }
              let deviceNos = []
              data.city = JSON.stringify(data.city)
              if (data.installDate) {
                data.installDate = formatDate(data.installDate)
              }

              this.form.terminalData.forEach((item) => {
                if (item.deviceNo) {
                  let deviceNo = item.deviceNo.split('(')[0]
                  deviceNos.push({
                    deviceNo: deviceNo,
                    deviceModelId: item.deviceModelId,
                    deviceModelType: item.deviceType,
                    videoChanel: item.videoChanel
                  })
                }
              })
              data.deviceNos = deviceNos

              if (this.vehicleNo) {
                updateVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('修改成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              } else {
                insertVehicle(data).then((res) => {
                  if (res.code === 1000) {
                    this.$message.success('新增成功')
                    this.$emit('saveEnd')
                  } else {
                    this.$message.error(res.msg)
                  }
                  this.$emit('onLoading', false)
                })
              }
            } else {
              return false
            }
          })
        }
      })
    },
    // 获取企业树
    getCompanyList () {
      getBusinessCompanyTree().then((res) => {
        if (res.code === 1000) {
          this.companyList = res.data
        }
      })
    },
    // 查询省市接口
    getAreaList () {
      queryAreaList().then((res) => {
        if (res.code === 1000) {
          this.cityList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 模糊查询所有未绑定车辆终端
    remoteMethod (query) {
      if (!this.addForm.companyId) {
        this.$message.warning('请先选择企业')
        return
      }
      if (query === '') {
        this.deviceNoList = []
        return
      }
      this.loading = true
      this.onSearchDeviceNo(query)
    },
    // 根据终端号模糊搜索所有未绑定车辆终端
    onSearchDeviceNo (deviceNo) {
      queryByDeviceNo({
        deviceNo: deviceNo,
        companyId: this.addForm.companyId
      }).then((res) => {
        if (res.code === 1000) {
          this.deviceNoList = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 选定设备号显示设备类型和sim卡号
    onChangeDeviceNo (index, row) {
      let deviceNo = row.deviceNo.split('(')[0]
      const item = this.deviceNoList.find(
        (element) => element.deviceNo === deviceNo
      )
      this.form.terminalData[index].SIMNo = item.sim || ''
      this.form.terminalData[index].deviceModelId = item.id || ''
      this.form.terminalData[index].deviceType = item.deviceModelType || ''
      this.form.terminalData[index].deviceTypeName = item.deviceName || ''
    },
    // 添加设备
    onAddTerminal () {
      this.form.terminalData.push({
        deviceNo: '',
        deviceType: '',
        deviceTypeName: '',
        SIMNo: '',
        videoChanel: ''
      })
    },
    // 删除设备
    onDeleteTerminal (i) {
      this.form.terminalData.splice(i, 1)
    },
    getAddGroupId (v) {
      this.addForm.companyId = v
    },
    //  查询车辆附加信息
    getVehicleInfo () {
      getVehicleInfo({ vehicleNo: this.rowData.vehicleNo }).then((res) => {
        if (res.code === 1000) {
          this.detailForm = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取详情
    getVehicleDetailInfo () {
      queryVehicleDetailInfo({ id: this.rowData.id }).then(res => {
        if (res.code === 1000) {
          this.addForm = res.data;
          this.addForm.city = JSON.parse(res.data.city)
          this.addForm.installDate = res.data.installDate ? new Date(res.data.installDate) : null;
          this.valueAdd = res.data.companyId;
          this.valueNameAdd = res.data.companyName;
          if (!this.addForm.vehicleDrivingLicense) {
            this.addForm.vehicleDrivingLicense = {
              drivingEnd: '',//行驶证有效截至日期
              drivingStart: '',
              drivingImg: '',//行驶证图片path
              drivingNumber: '',//行驶证号码
              licenseEnd: '',//线路牌号有效截至日期
              licenseStart: '',
              licenseNumber: '',
              transportNumber: '',//道路运输证号
              transportEnd: '',
              transportStart: '',
              transportImg: '',
              lineAllowEnd: ''
            }
          }
          if (!this.addForm.vehicleInsurance) {
            this.addForm.vehicleInsurance = {
              trafficStart: '',//交强险
              trafficEnd: '',
              trafficInsurance: '',
              tradeStart: '',//商业险
              tradeEnd: '',
              tradeInsurance: '',
              underwriteStart: '',//承运险
              underwriteEnd: '',
              underwriteInsurance: '',
              yearExamineStart: '',//年审
              yearExamineEnd: '',
              oneDefendStart: '',//一维
              oneDefendEnd: '',
              twoDefendStart: '',//二维
              twoDefendEnd: '',
            }
          }
          if (this.addForm.vehicleDrivingLicense.drivingImg) {
            this.drivingPhotos.push({
              url: this.addForm.vehicleDrivingLicense.drivingImg,
              name: 'pic1'
            })
          }
          if (this.addForm.vehicleDrivingLicense.transportImg) {
            this.transportPhotos.push({
              url: this.addForm.vehicleDrivingLicense.transportImg,
              name: 'pic2'
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getVehicleCertificateExpireDetail () {
      queryVehicleCertificateExpireDetail({ vehicleNo: this.rowData.vehicleNo }).then(res => {
        if (res.code === 1000) {
          this.vehicleCertificateExpireData = res.data;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    //获取字典值
    getDicts () {
      queryDictsByCodes({ parentCodes: 'VETY,CLZT,DLLX,CX,CPYS,CSYS,CLLB' }).then(
        (res) => {
          if (res.code === 1000) {
            this.typeList = res.data.VETY
            this.vehicleStatusList = res.data.CLZT
            this.powerTypeList = res.data.DLLX
            this.modelList = res.data.CX
            this.licensePlateColorList = res.data.CPYS
            this.vehicleColorList = res.data.CSYS;
            this.vehicleClassAssanationList = res.data.CLLB
          }
        }
      )
    }
  },
  created () {
    this.getDicts()
    this.getCompanyList()
    this.getAreaList()
    if (this.rowData) {
      //获取详情
      this.getVehicleDetailInfo();
      this.getVehicleInfo();
      this.getVehicleCertificateExpireDetail();
    }
  }
}
</script>

<style lang="scss" scoped>
.add-vehicle {
  @include themify() {
    .form-title {
      font-size: 14px;
      color: themed('n7');
      margin-bottom: 10px;
    }
    .form-title2 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
    }
    .head {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      color: themed('n8');
      font-size: 14px;
      padding: 12px 0;
      span {
        width: 160px;
        line-height: 32px;
      }
      p {
        padding: 0 12px;
        display: flex;
      }
      p:nth-of-type(1) {
        margin-bottom: 16px;
      }
    }
    /deep/.el-table:not(.no-blank) .el-table__empty-text {
      height: 60px;
    }
    /deep/.expired .el-input__inner {
      color: #ff1212 !important;
    }
  }
}
</style>
