var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body add-vehicle" },
    [
      _c("p", { staticClass: "form-title" }, [_vm._v("基本信息")]),
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          staticStyle: { "padding-bottom": "0" },
          attrs: {
            model: _vm.addForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "110px",
            "label-position": "left"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "车牌号：", prop: "cph" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入车牌号",
                  disabled: _vm.monitoringDisabled
                },
                model: {
                  value: _vm.addForm.cph,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "cph",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.cph"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "所属企业：", prop: "companyId" } },
            [
              _c("companySelect", {
                ref: "companySelect",
                attrs: {
                  clearable: true,
                  searchable: true,
                  disabled: _vm.monitoringDisabled,
                  companyTree: _vm.companyList,
                  valueName: _vm.valueNameAdd,
                  value: _vm.valueAdd
                },
                on: { getValue: _vm.getAddGroupId }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "车架号：",
                prop: _vm.isvehicleNoEdit ? "vehicleNo" : ""
              }
            },
            [
              _c("el-input", {
                attrs: {
                  placeholder: "请输入车架号",
                  disabled: !_vm.isvehicleNoEdit
                },
                model: {
                  value: _vm.addForm.vehicleNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "vehicleNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车辆状态：", prop: "vehicleStatus" } },
            [
              _c(
                "el-select",
                {
                  attrs: { disabled: "", clearable: "" },
                  model: {
                    value: _vm.addForm.vehicleStatus,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "vehicleStatus",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.vehicleStatus"
                  }
                },
                _vm._l(_vm.vehicleStatusList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车牌颜色：", prop: "licensePlateColor" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", disabled: _vm.monitoringDisabled },
                  model: {
                    value: _vm.addForm.licensePlateColor,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "licensePlateColor",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.licensePlateColor"
                  }
                },
                _vm._l(_vm.licensePlateColorList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车辆类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", disabled: _vm.monitoringDisabled },
                  model: {
                    value: _vm.addForm.type,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "type",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.type"
                  }
                },
                _vm._l(_vm.typeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车身颜色：", prop: "vehicleColor" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.vehicleColor,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "vehicleColor",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.vehicleColor"
                  }
                },
                _vm._l(_vm.vehicleColorList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "动力类型：" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.powerType,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "powerType",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.powerType"
                  }
                },
                _vm._l(_vm.powerTypeList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "城市：" } },
            [
              _c("el-cascader", {
                attrs: { size: "large", options: _vm.cityList },
                model: {
                  value: _vm.addForm.city,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "city", $$v)
                  },
                  expression: "addForm.city"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车型：", prop: "model" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.model"
                  }
                },
                _vm._l(_vm.modelList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "发动机号：", prop: "engineNo" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入发动机号" },
                model: {
                  value: _vm.addForm.engineNo,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "engineNo",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.engineNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "座位数：" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 23 } },
                    [
                      _c("el-input-number", {
                        staticClass: "add-vehicle-number",
                        attrs: {
                          "controls-position": "right",
                          min: 1,
                          max: 199
                        },
                        model: {
                          value: _vm.addForm.seatNum,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "seatNum", $$v)
                          },
                          expression: "addForm.seatNum"
                        }
                      })
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c("span", [_vm._v("座")])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "安装时间：", prop: "installDate" } },
            [
              _c("el-date-picker", {
                attrs: {
                  placeholder: "选择日期",
                  type: "date",
                  disabled: _vm.monitoringDisabled
                },
                model: {
                  value: _vm.addForm.installDate,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm, "installDate", $$v)
                  },
                  expression: "addForm.installDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "车辆类别：", prop: "vehicleClass" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.addForm.vehicleClass,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.addForm,
                        "vehicleClass",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "addForm.vehicleClass"
                  }
                },
                _vm._l(_vm.vehicleClassList, function(item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  autosize: { minRows: 2, maxRows: 2 },
                  maxlength: "500",
                  "show-word-limit": "",
                  placeholder: "请输入内容"
                },
                model: {
                  value: _vm.addForm.remark,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "p",
        { staticClass: "form-title form-title2" },
        [
          _c("span", [_vm._v("车辆终端信息")]),
          !_vm.disabled && !_vm.monitoringDisabled
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddTerminal }
                },
                [_vm._v("添加设备")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "dialog-form dialog-form-table",
          attrs: {
            model: _vm.form,
            disabled: _vm.disabled || _vm.monitoringDisabled
          }
        },
        [
          _c(
            "el-table",
            {
              staticClass: "deviceTable",
              attrs: { data: _vm.form.terminalData }
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", label: "序号", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "设备号", width: "330" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "terminalData." + scope.$index + ".deviceNo",
                              rules: _vm.tableRules.deviceNo
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  remote: "",
                                  "remote-method": _vm.remoteMethod,
                                  placeholder: "请输入关键字",
                                  cellclassname: "carGroupSelect"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onChangeDeviceNo(
                                      scope.$index,
                                      scope.row
                                    )
                                  }
                                },
                                model: {
                                  value: scope.row.deviceNo,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "deviceNo", $$v)
                                  },
                                  expression: "scope.row.deviceNo"
                                }
                              },
                              _vm._l(_vm.deviceNoList, function(item, key) {
                                return _c("el-option", {
                                  key: key,
                                  attrs: {
                                    label:
                                      item.deviceNo +
                                      "(" +
                                      (item.serialNo
                                        ? item.serialNo
                                        : "暂无自编号") +
                                      ")",
                                    value:
                                      item.deviceNo +
                                      "(" +
                                      (item.serialNo
                                        ? item.serialNo
                                        : "暂无自编号") +
                                      ")"
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deviceTypeName",
                  label: "设备类型",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "SIMNo",
                  label: "SIM卡号",
                  "show-overflow-tooltip": true
                }
              }),
              _c("el-table-column", {
                attrs: { label: "摄像头数量", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              prop:
                                "terminalData." + scope.$index + ".videoChanel",
                              rules: _vm.tableRules.videoChanel
                            }
                          },
                          [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.videoChanel,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "videoChanel", $$v)
                                  },
                                  expression: "scope.row.videoChanel"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "0", value: "0" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "1", value: "1" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "2", value: "2" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "3", value: "3" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "4", value: "4" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "5", value: "5" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "6", value: "6" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "7", value: "7" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "8", value: "8" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "9", value: "9" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "10", value: "10" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              !_vm.disabled && !_vm.monitoringDisabled
                ? _c("el-table-column", {
                    attrs: { label: "操作", width: "100" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function(scope) {
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "text",
                                    size: "small",
                                    sort: "danger",
                                    disabled:
                                      _vm.disabled || _vm.monitoringDisabled
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onDeleteTerminal(scope.$index)
                                    }
                                  }
                                },
                                [_vm._v("删除")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3859366344
                    )
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", [
        _vm._m(0),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("行驶证号码：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入行驶证号码" },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.drivingNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "drivingNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.drivingNumber"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.drivingEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.drivingEnd,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "drivingEnd",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.drivingEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("道路运输证号：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入道路运输证号" },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.transportNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "transportNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.transportNumber"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.transportEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期",
                  type: "date"
                },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.transportEnd,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "transportEnd",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.transportEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("线路牌号：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入线路牌号" },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.licenseNumber,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "licenseNumber",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.licenseNumber"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.licenseEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期",
                  type: "date"
                },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.licenseEnd,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "licenseEnd",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.licenseEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c("p", { staticStyle: { "line-height": "32px" } }, [
            _vm._v("客运班线经营许可证有效期：")
          ]),
          _c(
            "p",
            [
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.lineAllowEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期",
                  type: "date"
                },
                model: {
                  value: _vm.addForm.vehicleDrivingLicense.lineAllowEnd,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleDrivingLicense,
                      "lineAllowEnd",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleDrivingLicense.lineAllowEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "line-height": "148px",
                    "text-align": "center"
                  }
                },
                [_vm._v("行驶证照片")]
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  class: { disUoloadSty: _vm.isPhotoExceed },
                  attrs: {
                    action: _vm.uploadUrl(),
                    headers: _vm.getHeader(),
                    accept: "image/png, image/gif, image/jpg, image/jpeg",
                    "list-type": "picture-card",
                    limit: 1,
                    "file-list": _vm.drivingPhotos,
                    "before-remove": _vm.handleBeforeRemoveDrive,
                    "on-success": _vm.handleAvatarSuccessDrive
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            ],
            1
          ),
          _c(
            "p",
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "line-height": "148px",
                    "text-align": "center"
                  }
                },
                [_vm._v("道路运输证照片")]
              ),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  class: { disUoloadSty: _vm.isPhotoExceed2 },
                  attrs: {
                    action: _vm.uploadUrl(),
                    headers: _vm.getHeader(),
                    accept: "image/png, image/gif, image/jpg, image/jpeg",
                    "list-type": "picture-card",
                    limit: 1,
                    "file-list": _vm.transportPhotos,
                    "before-remove": _vm.handleBeforeRemove,
                    "on-success": _vm.handleAvatarSuccess
                  }
                },
                [_c("i", { staticClass: "el-icon-plus" })]
              )
            ],
            1
          )
        ]),
        _vm._m(1),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("交强险生效：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.trafficStart,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "trafficStart", $$v)
                  },
                  expression: "addForm.vehicleInsurance.trafficStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.trafficEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.trafficEnd,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "trafficEnd", $$v)
                  },
                  expression: "addForm.vehicleInsurance.trafficEnd"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("承保公司：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入承保公司" },
                model: {
                  value: _vm.addForm.vehicleInsurance.trafficInsurance,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "trafficInsurance",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.trafficInsurance"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("商业险生效：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.tradeStart,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "tradeStart", $$v)
                  },
                  expression: "addForm.vehicleInsurance.tradeStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.tradeEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.tradeEnd,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "tradeEnd", $$v)
                  },
                  expression: "addForm.vehicleInsurance.tradeEnd"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("承保公司：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入承保公司" },
                model: {
                  value: _vm.addForm.vehicleInsurance.tradeInsurance,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "tradeInsurance",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.tradeInsurance"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("承运险生效：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.underwriteStart,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "underwriteStart",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.underwriteStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.underwriteEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.underwriteEnd,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "underwriteEnd", $$v)
                  },
                  expression: "addForm.vehicleInsurance.underwriteEnd"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("承保公司：")]),
              _c("el-input", {
                attrs: { placeholder: "请输入承保公司" },
                model: {
                  value: _vm.addForm.vehicleInsurance.underwriteInsurance,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "underwriteInsurance",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.underwriteInsurance"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("年审日期：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.yearExamineStart,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "yearExamineStart",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.yearExamineStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.yearExamineEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.yearExamineEnd,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "yearExamineEnd",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.yearExamineEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("一维日期：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.oneDefendStart,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "oneDefendStart",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.oneDefendStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.oneDefendEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.oneDefendEnd,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "oneDefendEnd", $$v)
                  },
                  expression: "addForm.vehicleInsurance.oneDefendEnd"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "head" }, [
          _c(
            "p",
            [
              _c("span", [_vm._v("二维日期：")]),
              _c("el-date-picker", {
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.twoDefendStart,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.addForm.vehicleInsurance,
                      "twoDefendStart",
                      $$v
                    )
                  },
                  expression: "addForm.vehicleInsurance.twoDefendStart"
                }
              })
            ],
            1
          ),
          _c(
            "p",
            [
              _c("span", [_vm._v("有效期至：")]),
              _c("el-date-picker", {
                class:
                  _vm.vehicleCertificateExpireData &&
                  _vm.vehicleCertificateExpireData.twoDefendEnd
                    ? "expired"
                    : "",
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  type: "date",
                  placeholder: "选择日期"
                },
                model: {
                  value: _vm.addForm.vehicleInsurance.twoDefendEnd,
                  callback: function($$v) {
                    _vm.$set(_vm.addForm.vehicleInsurance, "twoDefendEnd", $$v)
                  },
                  expression: "addForm.vehicleInsurance.twoDefendEnd"
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-title form-title2" }, [
      _c("span", [_vm._v("行驶证信息")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "form-title form-title2" }, [
      _c("span", [_vm._v("保险信息")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }